import Vue from 'vue';
/* eslint-disable  */

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import store from '@/store';
import { AXIOS_DEFAULT } from '@/constant/constants';

const api = axios.create({
  ...AXIOS_DEFAULT,
  responseType: 'json',
});

const downloadApi = axios.create({
  ...AXIOS_DEFAULT,
  responseType: 'blob',
});

function errorParser(data: any): string[] {
  if (data.errors) {
    const errors = Object.values(data.errors).map((error: any) => error[0]);
    return errors;
  }
  return [data.message];
}

const isGetRequest = (method?: Method) => method === 'GET' || method === 'get';

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const configClone = { ...config };

  if (localStorage.getItem('access_token')) {
    configClone.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    configClone.headers.Accept = 'application/json';
  }
  //   if (!isGetRequest(config.method)) {
  //     store.dispatch('toggleLoader', true);
  //   }

  return configClone;
});

downloadApi.interceptors.request.use((config: AxiosRequestConfig) => {
  const configClone = { ...config };

  if (localStorage.getItem('access_token')) {
    configClone.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    configClone.headers.Accept = 'application/json';
  }
  //   if (!isGetRequest(config.method)) {
  //     store.dispatch('toggleLoader', true);
  //   }

  return configClone;
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (!isGetRequest(response.config.method)) {
      const { message } = response.data;
      if( response.data.error !== undefined) {
        Vue.toasted.show(message, {
          type: 'error',
        });
      } else {
        Vue.toasted.show(message, {
          type: 'success',
        });
        //   store.dispatch('toggleLoader', false);
      }
    }
    return response;
  },
  (errors: AxiosError) => {
    const errorMessage = errors.response
      ? errorParser(errors.response.data)
      : ['Something went wrong.'];

    errorMessage.forEach((error) => {
      Vue.toasted.show(error, {
        type: 'error',
      });
    });

    // if (!isGetRequest(errors.config.method)) store.dispatch('toggleLoader', false);

    return Promise.reject(errors);
  },
);

downloadApi.interceptors.response.use(
  (response: AxiosResponse) => {
    if (!isGetRequest(response.config.method)) {
      const { message } = response.data;
      Vue.toasted.show(message, {
        type: 'success',
      });
      //   store.dispatch('toggleLoader', false);
    }
    return response;
  },
  (errors: AxiosError) => {
    const errorMessage = errors.response
      ? errorParser(errors.response.data)
      : ['Something went wrong.'];

    errorMessage.forEach((error) => {
      Vue.toasted.show(error, {
        type: 'error',
      });
    });

    // if (!isGetRequest(errors.config.method)) store.dispatch('toggleLoader', false);

    return Promise.reject(errors);
  },
);

export  {api , downloadApi};
