import Vue from 'vue';

const currencyFormat = new Intl.NumberFormat('DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

const dateFormat = new Intl.DateTimeFormat('DE', { year: 'numeric', month: '2-digit', day: '2-digit' });

Vue.filter('formatPrice', (value: number): string => currencyFormat.format(value));
Vue.filter('formatDate', (value: Date): string => dateFormat.format(value));
