/* eslint-disable */
function detectIE() {
  // Get the user-agent string
  const userAgentString = navigator.userAgent;
  if (userAgentString.indexOf("Firefox") > -1) {
    return 0;
  } else {
    // Detect Internet Explorer
    return userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;
  }
}

export { detectIE };
