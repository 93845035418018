<template>
  <div class="row mt-2 priceError">
      <span class="priceErrorText">{{body}}</span>
<!--    <span class="priceErrorClose" @click="closeError">x</span>-->
  </div>
</template>

<script>
export default {
  name: 'error-message',
  props: {
    body: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  methods: {
    closeError() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
  .priceError {
    display: flex;
    padding: 15px;
    background-color: red;
    border-radius: 6px;
    color: white;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .priceErrorText{
    flex: 0 95%;
  }
  .priceErrorClose{
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    line-height: 50%;
    color: #fff;
    cursor: pointer;
  }
</style>
