


































import Vue from 'vue';

export default class ResetPassword extends Vue {
  check = false;

  email = '';

  mounted() {
    this.check = true;
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  }
}
