











































import Vue from 'vue';
// eslint-disable-next-line object-curly-newline
import { LOGISTIC, HOME_BANNER, OFFERS, DOCUMENTS } from '@/config/images';

export default Vue.extend({
  name: 'HomeMain',
  data: () => ({
    products: [
      {
        id: 1,
        name: 'Logistik Versicherung 4.0',
        description: 'Auswählen',
        image: LOGISTIC,
      },
      {
        id: 2,
        name: 'Logistik Versicherung 4.0',
        description: 'Auswählen',
        image: LOGISTIC,
      },
      {
        id: 3,
        name: 'Logistik Versicherung 4.0',
        description: 'Auswählen',
        image: LOGISTIC,
      },
      {
        id: 4,
        name: 'Logistik Versicherung 4.0',
        description: 'Auswählen',
        image: LOGISTIC,
      },
      {
        id: 5,
        name: 'Logistik Versicherung 4.0',
        description: 'Auswählen',
        image: LOGISTIC,
      },
    ],
    HOME_BANNER,
    OFFERS,
    DOCUMENTS,
  }),
  mounted() {
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
  },
});
