

















































































import Vue from 'vue';

import { LOGO, PHONE_LOGO, MAIL_LOGO } from '@/config/images';
import { getUser } from '@/helper/handle-auth';
import { WEBSITE_URL } from '@/constant/constants';
import priceCalculator from '@/service/price-calculator';
// import { AuthResponse } from '@/model/auth';

export default Vue.extend({
  name: 'AppHeader',
  props: ['id', 'LoggedIn', 'customerData'],
  watch: {
    $route(to, from) {
      this.user = getUser();
      if (this.user !== null && this.user !== undefined) {
        this.$emit('LoggedIn', true);
      }
    },
  },
  data() {
    return {
      LOGO,
      PHONE_LOGO,
      MAIL_LOGO,
      // isLoggedIn: true,
      showPopover: false,
      showCollapseMenu: false,
      user: getUser(),
      WEBSITE_URL,
    };
  },
  mounted() {
    this.user = getUser();
    if (this.user) {
      this.$emit('LoggedIn', true);
    }
  },
  methods: {
    connectToOldSystem() {
      priceCalculator.connectToOldSystem().then((response) => {
        window.open(response.data);
      });
    },
  },
});
