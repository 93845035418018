import LOGO from '@/assets/images/logo.png';
import PHONE_LOGO from '@/assets/images/phone_logo.png';
import MAIL_LOGO from '@/assets/images/mail_logo.png';
import LOGISTIC from '@/assets/images/calc_logistic.jpg';
import TRANSPORT from '@/assets/images/transport-versicherung.jpg';
import BOAT from '@/assets/images/calc_boat.jpg';
import SECURE from '@/assets/images/calc_secur.jpg';
import SHOWS from '@/assets/images/calc_shows.jpg';
import LUGGAGE from '@/assets/images/calc_luggage.jpg';
import WORK from '@/assets/images/calc_works.jpg';
import HOME_BANNER from '@/assets/images/banner2.jpg';
import OFFERS from '@/assets/images/offers.png';
import DOCUMENTS from '@/assets/images/documents.png';
import CARS_PHOTO from '@/assets/images/ausstellungsversicherung.jpg';
import GLASS_PHOTO from '@/assets/images/musterkollektions-versicherung.jpg';
import MARKET_PHOTO from '@/assets/images/marktkonzept.jpg';

export {
  LOGO,
  PHONE_LOGO,
  MAIL_LOGO,
  LOGISTIC,
  HOME_BANNER,
  OFFERS,
  DOCUMENTS,
  TRANSPORT,
  BOAT,
  SECURE,
  LUGGAGE,
  WORK,
  SHOWS,
  CARS_PHOTO,
  GLASS_PHOTO,
  MARKET_PHOTO,
};

export default null;
