import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDollarSign,
  faEuroSign,
  faPercentage,
  faAngleDown,
  faSearch,
  faAngleUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faEuroSign,
  faDollarSign,
  faPercentage,
  faAngleDown,
  faAngleUp,
  faSearch,
  faTimesCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
