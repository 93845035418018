import { AuthResponse } from '@/model/auth';
/* eslint-disable */
export function login(user: AuthResponse) {
  localStorage.setItem('id', user.id);
  localStorage.setItem('first_name', user.first_name);
  localStorage.setItem('last_name', user.last_name);
  localStorage.setItem('phone', user.phone);
  localStorage.setItem('street', user.street);
  localStorage.setItem('zip', user.zip);
  localStorage.setItem('town', user.town);
  localStorage.setItem('country', user.country);
  localStorage.setItem('agent_number', user.agent_number);
  localStorage.setItem('email', user.email);
  localStorage.setItem('access_token', user.access_token);
  const role = Array();
    role.push(user.roles.map((e: any ) => { return e.name}));
  localStorage.setItem('roles', String(role));
}

export function logout() {
  localStorage.clear();
}

export function getUser(): AuthResponse | void {
  const access_token = localStorage.getItem('access_token');
  const email = localStorage.getItem('email');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const phone = localStorage.getItem('phone');
  const street = localStorage.getItem('street');
  const zip = localStorage.getItem('zip');
  const town = localStorage.getItem('town');
  const country = localStorage.getItem('country');
  const agent_number = localStorage.getItem('agent_number');
  const id = localStorage.getItem('id');
  const roles = localStorage.getItem('roles');

  let returnValue;
  if (access_token && email && first_name && last_name && id && roles && phone && street && zip && town && country && agent_number) {
    returnValue = {
      id,
      access_token,
      first_name,
      last_name,
      phone,
      street,
      zip,
      town,
      country,
      agent_number,
      email,
      roles,
    };
  }
  return returnValue;
}
