import { api } from './instance';

const user = {
  login: (data: object) => api.post('login', data),
  getInsurance: (APIUrl: string, token: string) => api.get(APIUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
  loginAPI: (data: object) => api.post('loginAPI', data),
};

const insurance = {};
export { user, insurance };
