import Vue from 'vue';
import Toast from 'vue-toasted';
import VTooltip from 'v-tooltip';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/config/filter';
import './font-awesome';

import '@/assets/css/main.css';
import '@/assets/css/custom.css';

Vue.config.productionTip = false;

Vue.use(VTooltip, {
  defaultTrigger: 'click',
});

Vue.use(VueTheMask);

Vue.use(Toast, {
  position: 'top-right',
  duration: 2000,
  fullWidth: false,
  iconPack: 'fontawesome',
  closeOnSwipe: true,
  action: {
    text: 'Close',
    icon: 'fa-time',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: { prefix: '', suffix: ' EUR' },
    locale: 'DE',
    autoDecimalMode: true,
    precision: 0,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
